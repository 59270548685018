<template>
  <div class="vg_wrapper">
    <el-card>
      <div class="vd_search_group">
        <el-form
          :inline="true"
          size="min"
          :model="searchForm"
          ref="searchForm"
          @keyup.enter.native="getMstksNow()"
        >
          <el-row>
            <el-col :md="8">
              <el-form-item label="物料编号:">
                <el-input
                  size="small"
                  v-model.trim="searchForm.mtrl_no"
                  clearable
                  placeholder="请填写物料编号"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="物料名称:">
                <el-input
                  size="small"
                  v-model.trim="searchForm.mtrl_name"
                  clearable
                  placeholder="请填写物料名称"
                ></el-input>
              </el-form-item>
            </el-col> 
            <!-- <el-col :md="8">
              <el-form-item label="库存状态:">
                <el-select size="small" v-model="searchForm.mstk_status" placeholder="请选择库存状态" clearable >
                  <el-option
                      v-for="item in state"
                      :key="item.id"
                      :label="item.label"
                      :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col> -->
            <el-col :md="8" v-if="userInfo.acct_id==1">
              <el-form-item label="所属账套:">
                <el-select
                  v-model="searchForm.acct_id"
                  filterable
                  placeholder="请选择所属账套"
                  clearable
                  size="small"
                  @change="getMstksNow()"
                >
                  <el-option
                    v-for="item in acctList"
                    :key="item.acct_id"
                    :label="item.acct_no"
                    :value="item.acct_id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :md="8">
              <el-form-item label="潘通色号:">
                <el-input size="small" v-model.trim="searchForm.mtrl_color" clearable placeholder="请填写潘通色号"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="物料类型:">
                <el-select
                  size="small"
                  v-model="searchForm.mtrl_type"
                  placeholder="请选择物料类型"
                  clearable
                >
                  <el-option
                    v-for="item in mtrlType"
                    :key="item.id"
                    :label="item.label"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :md="16">
              <el-form-item label="修改时间:">
                <div class="block vg_mr_32">
                  <el-date-picker
                    size="small"
                    v-model="searchForm.timeValue"
                    type="datetimerange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                  >
                  </el-date-picker>
                </div>
              </el-form-item>
              <el-form-item>
                <el-button
                  size="small"
                  type="primary"
                  icon="el-icon-search"
                  @click="getMstksNow()"
                  class="vg_ml_16"
                  >查询</el-button
                >
                <el-button
                  type="info"
                  size="small"
                  class="vd_export"
                  @click="buttonRefresh()"
                  ><i class="el-icon-refresh-right"></i> 刷新</el-button
                >
              </el-form-item>
            </el-col>
          </el-row>
          <el-row >
            <el-col>
              <el-form-item>
                <el-button
                  v-if="userInfo.acct_id !== 1"
                  type="danger"
                  size="small"
                  class="vd_export"
                  @click="remove()"
                  ><i class="el-icon-delete"></i> 删除</el-button
                >
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="vd_button_group vg_mtb_16"></div>
      <el-row>
        <el-col :md="24">
          <el-table
            ref="multiTable"
            :data="tableData"
            border
            v-loading="loadingFlag"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" v-if="userInfo.acct_id !== 1" align="center"></el-table-column>
            <el-table-column label="物料编号" prop="mtrl_no" />
            <el-table-column label="物料图片" align="center">
              <template slot-scope="scope">
                <el-image
                  v-if="scope.row.imge_url"
                  class="vd_elimg"
                  :src="formatPic(scope, 's')"
                  fit="fill"
                >
                </el-image>
                <!-- :src="helper.picUrl(scope.row.imge_url,'m')"   -->
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column
              label="物料名称"
              prop="mtrl_name"
              show-overflow-tooltip
            />
            <el-table-column
              label="物料类型"
              prop="mtrl_type"
              :formatter="formatLeavType"
            />
            <el-table-column label="潘通色号" prop="mtrl_color">
              <template slot-scope="scope">
                <span v-if="scope.row.mtrl_color">
                  <div class="vd_dis">
                    <div
                      :class="{ vd_div: scope.row.colr_html }"
                      :style="{ 'background-color': scope.row.colr_html }"
                    ></div>
                    <span>{{ scope.row.mtrl_color }}</span>
                  </div>
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="库存数量" prop="mstk_num">
              <template slot-scope="scope">
                <span v-if="scope.row.mstk_num">
                  {{ scope.row.mstk_num | formatMstkNum }}
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="物料单位" prop="mtrl_unit">
              <template slot-scope="scope">
                <span v-if="scope.row.mtrl_unit">
                  {{ scope.row.mtrl_unit }}
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="安全库存" prop="mtrl_stock_limit">
              <template slot-scope="scope">
                {{ scope.row.mtrl_stock_limit | formatMtrlStockLimit }}
              </template>
            </el-table-column>
            <el-table-column label="物料毛高">
              <template slot-scope="scope">
                <div v-if="scope.row.mtrl_thick || scope.row.mtrl_thick === 0">
                  {{ scope.row.mtrl_thick | formatMtqrMtrlThick }}毫米
                </div>
                <div v-else class="vg_9f9a9a">暂无</div>
              </template>
            </el-table-column>
            <el-table-column
              label="修改时间"
              prop="update_time"
              :formatter="formatDate"
            >
            </el-table-column>
            <el-table-column label="所属账套" prop="acct_no" v-if="userInfo.acct_id===1"/>
            <el-table-column label="所属公司" prop="cptt_name" show-overflow-tooltip v-if="userInfo.acct_id==1"/>
            <!-- <el-table-column label="库存状态" :show-overflow-tooltip='true' align="center">
              <template slot-scope="scope"> -->
            <!-- {{scope.row}} -->
            <!-- <el-tag :type="getStatusName(scope.row.mstk_status).type" size="mini">{{getStatusName(scope.row.mstk_status).name}}</el-tag> -->
            <!-- </template> -->
            <!-- </el-table-column> -->
          </el-table>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="24">
          <pubPagination
            :totalPage="totalPage"
            @changePageSearch="changePageSearch"
            ref="pubPagination"
          ></pubPagination>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import { get, post } from "@api/request";
import { mstkAPI } from "@api/modules/mstk";
import pubPagination from "@/components/common/pubPagination";
import helper from "@assets/js/helper.js";
import { acctAPI } from "@api/modules/acct";

export default {
  name: "MstkList",
  components: {
    pubPagination,
  },
  data() {
    return {
      tableData: [],
      searchForm: {
        mtrl_no: null,
        mtrl_name:null,
        mtrl_type: null,
        mtrl_color:null,
        acct_id: 2,
        timeValue: {
          startTime: null,
          endTime: null,
        },
        mstk_status: null,
      },
      totalPage: 0,
      btn: {},
      loadingFlag: true,
      currentPage: 1,
      mtrlType: [
        { id: 0, label: "原面料" },
        { id: 1, label: "辅料" },
        { id: 2, label: "包材" },
        { id: 3, label: "加工面料" },
      ],
      state: [
        { id: 0, label: "库存不足" },
        { id: 1, label: "库存充足" },
      ],
      multipleSelection: [],
      userInfo: null,
      acctList: [],
    };
  },
  created() {
    if (this.$route.query.mtrl_no) {
      this.searchForm.mtrl_no = this.$route.query.mtrl_no;
    }
    this.initData();
  },
  mounted() {
    this.$EventBus.$on("uploading", (res) => {
      this.initData();
    });
  },
  filters: {
    formatMstkNum(row) {
      return helper.haveFour(row);
    },
    formatMtrlStockLimit(row) {
      return helper.haveFour(row);
    },
    formatMtqrMtrlThick(row) {
      return helper.reservedDigits(row);
    },
  },
  methods: {
    initData() {
      this.loadingFlag = true;
      this.getUserInfo();
      if(this.userInfo.acct_id==1){
       this.getAcct()
     }else{
       this.getMstksList()
     }
    
    },
    getUserInfo() {
      this.userInfo = this.$cookies.get("userInfo");
    },
    //获取审批账套
    getAcct() {
      get(acctAPI.getAllAcctsV1)
        .then((res) => {
          if (res.data.code == 0) {
            this.acctList = res.data.data;
            setTimeout(() => {
              this.getMstksList();
            }, 500);
            return;
          }
          return this.$message.error(res.data.msg);
        })
        .catch((res) => {
          let mg = res.data.msg;
          let tp = "error";
          this.$message({ message: mg, type: tp });
        });
    },
    // 获取二维码信息
    getMstksList() {
      if(this.userInfo.acct_id!=1){
        this.searchForm.acct_id = null
      }
      const timeNewVal = this.helper.getTime(this.searchForm.timeValue);
      get(mstkAPI.getMstks, {
        mtrl_no: this.searchForm.mtrl_no,
        mtrl_name:this.searchForm.mtrl_name,
        mtrl_type: this.searchForm.mtrl_type,
        mtrl_color:this.searchForm.mtrl_color,
        start_time: timeNewVal.startTime,
        end_time: timeNewVal.endTime,
        page_no: this.currentPage,
        mstk_status: this.searchForm.mstk_status,
        acct_id: this.searchForm.acct_id,
      })
        .then((res) => {
          if (res.data.code === 0) {
            this.loading = false;
            this.tableData = res.data.data.list;
            this.totalPage = res.data.data.total;
            this.btn = res.data.data.btn;
            setTimeout(() => {
              this.loadingFlag = false;
            }, 500);
          } else {
            let mg = res.data.msg;
            let tp = "error";
            this.$message({ message: mg, type: tp });
          }
        })
        .catch((res) => {
          let mg = res.data.msg;
          let tp = "error";
          this.$message({ message: mg, type: tp });
        });
    },
    // 删除
    remove() {
      if (this.multipleSelection.length == 0) {
        return this.$message.warning("至少选择一条数据");
      }
      this.$confirm("此操作将永久删除该物料, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let mulArr = [];
          this.multipleSelection.forEach((item) => {
            mulArr.push(item.mstk_id);
          });
          post(mstkAPI.destroy_mstk_by_ids, { mstk_id_list: mulArr })
            .then((res) => {
              if (res.data.code == 0) {
                this.$message.success(res.data.msg);
                if (mulArr.length === this.tableData.length) {
                  if (this.currentPage > 1) {
                    this.currentPage = this.currentPage - 1;
                    this.$refs.pubPagination.currentPage = this.currentPage;
                  }
                }
                this.initData();
                return;
              }
              this.$message.warning(res.data.msg);
            })
            .catch((res) => {
              this.$message.error(res.data.msg);
            });
        })
        .catch((res) => {});
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    // 刷新
    buttonRefresh() {
      this.searchForm = {
        mtrl_no: null,
        mtrl_name:null,
        mtrl_type: null,
        mtrl_color: null,
        timeValue: {
          startTime: null,
          endTime: null,
        },
      };
      this.loadingFlag = true;
      this.currentPage = 1;
      this.initData();
      this.$refs.pubPagination.currentPage = 1;
    },
    // 转换合同状态
    getStatusName(status) {
      if (status === 1) {
        return { name: "库存充足", type: "success" };
      } else if (status === 0) {
        return { name: "库存不足", type: "warning" };
      }
    },
    // 查询方法
    getMstksNow() {
      this.loadingFlag = true;
      this.$refs.pubPagination.currentPage = 1;
      this.currentPage = 1;
      this.initData();
    },
    // 状态查询
    getStatusVal(val) {
      this.searchForm.status = val;
    },
    // 分页查询
    changePageSearch(val) {
      this.currentPage = val;
      this.initData();
    },
    // 物料类型
    formatLeavType(row) {
      if (row.mtrl_type === 0) {
        return "原面料";
      } else if (row.mtrl_type === 1) {
        return "辅料";
      } else if (row.mtrl_type === 2) {
        return "包材";
      }
    },
    // 时间转换
    formatDate(row) {
      return this.helper.toStringDate(row.update_time);
    },
    // 图片类型
    formatPic(scope, type) {
      let updateTime = JSON.parse(JSON.stringify(new Date().getTime()));
      return this.helper.picUrl(
        scope.row.imge_url,
        type,
        updateTime,
        scope.row.imge_source
      );
    },
  },
};
</script>

<style scoped lang="scss">
.vd_elimg {
  width: 60px;
  height: 60px;
  margin-top: 5px;
}
.vd_dis {
  display: flex;
}
.vd_div {
  width: 40px;
  height: 20px;
  border: none;
  margin-right: 5px;
}
</style>
